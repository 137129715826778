import * as React from "react"
import Feature1 from "../../svg/features/feature-1"
import Feature2 from "../../svg/features/feature-2"
import Feature3 from "../../svg/features/feature-3"
import Feature4 from "../../svg/features/feature-4"
import Feature5 from "../../svg/features/feature-5"
import Feature6 from "../../svg/features/feature-6"

const data = [
  {
    Icon: Feature1,
    title: "Imprezy okolicznościowe",
    description:
      "Profesjonalnie zadbamy o bezpieczny oraz komfortowy transport Twoich gości.",
  },
  {
    Icon: Feature2,
    title: "Transfery na lotniska",
    description:
      "Zapewniamy sprawne przewozy do pobliskich lotnisk - Goleniów, Berlin Tegel, Schönefeld.",
  },
  {
    Icon: Feature3,
    title: "Transport towarowy",
    description:
      "Oferujemy transport zarówno mniejszych paczek jak i dużych przesyłek (bagażówki).",
  },
  {
    Icon: Feature4,

    title: "Przewozy cykliczne",
    description:
      "Transport pracowników, dzieci. Stałe zlecenie to referencyjne warunki dla firm oraz rodziców.",
  },
  {
    Icon: Feature5,
    title: "Transfery VIP",
    description:
      "Komfort, bezpieczństwo, czas. Twoi klienci zostaną obsłużeni z należytym standardem.",
  },
  {
    Icon: Feature6,
    title: "Zakupy",
    description:
      "Pomożemy w wykonaniu oraz dostarczeniu zakupów, Ty nie musisz się nigdzie ruszać.",
  },
]

const Features = () => {
  return (
    <div className={"features"}>
      {data.map(({ Icon, title, description }, index) => (
        <div key={title} className={"features__feature"}>
          <Icon />
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      ))}
    </div>
  )
}

export default Features
