import * as React from "react"
const Feature4 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={46}
    viewBox="0 0 44 46"
    fill="none"
  >
    <path
      d="M2 12C2 8.68629 4.68629 6 8 6H36C39.3137 6 42 8.68629 42 12V38C42 41.3137 39.3137 44 36 44H8C4.68629 44 2 41.3137 2 38V12Z"
      fill="url(#paint0_linear_646_1994)"
    />
    <path
      d="M12 2V6M32 2V6M2 16H42M13 26H17M27 26H31M13 34H17M27 34H31M8 44H36C39.3137 44 42 41.3137 42 38V12C42 8.68629 39.3137 6 36 6H8C4.68629 6 2 8.68629 2 12V38C2 41.3137 4.68629 44 8 44Z"
      stroke="#262338"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_646_1994"
        x1={2}
        y1="14.81"
        x2="42.9505"
        y2="18.2743"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
    </defs>
  </svg>
)

export default Feature4
