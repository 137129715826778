import * as React from "react"
import Send from "../../svg/form/send"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"
import Phone from "../../svg/phone"
import Locals from "../../svg/about-us/locals"

const AboutHero = () => {
  const { handleSubmit, register } = useForm({})
  const onSubmit = data => console.log(data)

  return (
    <div className={"about-hero"}>
      <h1>Taxi Prawobrzeże</h1>
      <div data-g="grid">
        <div data-g="4 12@sm 6@md">
          <p>
            Firma Taxi Prawobrzeże powstała w 1996 roku. Początkowo
            funkcjonowała pod nazwą Intertaxi, a jej flota składała się jedynie
            z kilku pojazdów. Komunikacja między kierowcami a dyspozytorami
            odbywała się drogą radiową, co wówczas było standardem.
          </p>
          <p>
            Obecnie Taxi Prawobrzeże to prężnie rozwijająca się firma,
            największa korporacja taksówkowa na prawym brzegu Szczecina. Dziś
            nasz flota składa się z kilkudziesięciu samochodów, wyposażonych w
            tzw. terminale pozwalające na sprawną komunikację wewnątrz firmy.
          </p>
        </div>
        <div data-g="4 12@sm 6@md">
          <p>
            Jesteśmy nowoczesną firmą, nasza infrastruktura komunikacyjna jest w
            całości skomputeryzowana, a nasi kierowcy są wspierani przez
            urządzenia GPS umożliwiające ich szybka lokalizacje przez
            dyspozytorów.
          </p>
          <p>
            Jeżeli maja państwo jakiekolwiek uwagi dotyczące naszej
            działalności, prosimy je wysyłać na adres email. Pomoże to nam
            zwiększyć jakość świadczonych przez nas usług. Zachęcamy do
            zapoznania się z naszą ofertą i skorzystania z usług Taxi
            Prawobrzeże.
          </p>
        </div>
      </div>
      <div data-g="grid">
        <div data-g="4 12@sm 6@md">
          <div className="about-hero__buttons">
            <Link className={"button button--primary"} to={"/oferta"}>
              Poznaj ofertę
            </Link>
            <Link className={"button button--secondary"} to={"/dolacz"}>
              Zostań kierowcą
            </Link>
          </div>
        </div>
        <div data-g="4 12@sm 6@md">
          <img
            src="/images/about-us/from-here.svg"
            alt="jesteśmy stąd, jeździmy dla was"
          />
        </div>
      </div>
    </div>
  )
}

export default AboutHero
