import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Cars = () => {
  return (
    <div className={"about-cars"}>
      <div data-g={"hide show@sm"}>
        <StaticImage src="../../images/about-us.png" alt="samochody" />
      </div>
      <div data-g={"hide@sm"} className={"about-cars__mobile"}>
        <StaticImage src="../../images/about-us/car1.png" alt="samochody" />
        <StaticImage src="../../images/about-us/car2.png" alt="samochody" />
        <StaticImage src="../../images/about-us/car3.png" alt="samochody" />
        <StaticImage src="../../images/about-us/car4.png" alt="samochody" />
      </div>
      <div data-g="grid" className={"about-cars__content"}>
        <div data-g="4 6@sm 6@md">
          <div className="about-cars__heading">Biuro</div>
          <div className="about-cars__description">
            ul. Struga 44, 70-787 Szczecin <br />
            Budynek “Merida”. I. Piętro, pokój numer 16
          </div>
        </div>
        <div data-g="4 6@sm 6@md">
          <div className="about-cars__heading">Kontakt</div>
          <div className="about-cars__description">
            tel.: 914 615 615, 914 612 612 <br />
            email: kontakt@taxiprawobrzeze.pl
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cars
