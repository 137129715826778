import * as React from "react"
const Feature3 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={40}
    viewBox="0 0 44 40"
    fill="none"
  >
    <path
      d="M2 4C2 2.89543 2.89543 2 4 2H40C41.1046 2 42 2.89543 42 4V12H2V4Z"
      fill="url(#paint0_linear_646_1986)"
    />
    <path
      d="M4 12H40V36C40 37.1046 39.1046 38 38 38H6C4.89543 38 4 37.1046 4 36V12Z"
      fill="url(#paint1_linear_646_1986)"
    />
    <path
      d="M42 12V13.5C42.8284 13.5 43.5 12.8284 43.5 12H42ZM2 12H0.5C0.5 12.8284 1.17157 13.5 2 13.5L2 12ZM4 12V10.5C3.17157 10.5 2.5 11.1716 2.5 12H4ZM40 12H41.5C41.5 11.1716 40.8284 10.5 40 10.5V12ZM18 20.5C17.1716 20.5 16.5 21.1716 16.5 22C16.5 22.8284 17.1716 23.5 18 23.5V20.5ZM26 23.5C26.8284 23.5 27.5 22.8284 27.5 22C27.5 21.1716 26.8284 20.5 26 20.5V23.5ZM4 3.5H40V0.5H4V3.5ZM40.5 4V12H43.5V4H40.5ZM42 10.5H2V13.5H42V10.5ZM3.5 12V4H0.5V12H3.5ZM40 3.5C40.2761 3.5 40.5 3.72386 40.5 4H43.5C43.5 2.067 41.933 0.5 40 0.5V3.5ZM4 0.5C2.067 0.5 0.5 2.067 0.5 4H3.5C3.5 3.72386 3.72386 3.5 4 3.5V0.5ZM4 13.5H40V10.5H4V13.5ZM38.5 12V36H41.5V12H38.5ZM38 36.5H6V39.5H38V36.5ZM5.5 36V12H2.5V36H5.5ZM6 36.5C5.72386 36.5 5.5 36.2761 5.5 36H2.5C2.5 37.933 4.06701 39.5 6 39.5V36.5ZM38.5 36C38.5 36.2761 38.2761 36.5 38 36.5V39.5C39.933 39.5 41.5 37.933 41.5 36H38.5ZM18 23.5H26V20.5H18V23.5Z"
      fill="#262338"
    />
    <defs>
      <linearGradient
        id="paint0_linear_646_1986"
        x1={2}
        y1="12.98"
        x2="42.8457"
        y2="17.0114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_646_1986"
        x1={2}
        y1="12.98"
        x2="42.8457"
        y2="17.0114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
    </defs>
  </svg>
)

export default Feature3
