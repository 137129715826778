import * as React from "react"
const Feature2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={45}
    viewBox="0 0 45 45"
    fill="none"
  >
    <path
      d="M8.72792 11.2721L13.3563 6.64374L29.5554 10.2436L34.0661 5.73287C34.1445 5.65447 34.2229 5.58196 34.3089 5.51197C35.1918 4.79328 40.5111 0.628803 42.669 2.7868C44.827 4.94479 40.6626 10.264 39.9439 11.147C39.8739 11.2329 39.8014 11.3113 39.723 11.3897L35.2123 15.9004L38.8121 32.0996L34.1838 36.7279L26.8299 23.8586L17.2132 32.4853L18.3703 38.3993L14.3848 42.3848L10.6564 34.7994L3.07107 31.0711L7.05658 27.0856L12.9706 28.2426L21.5973 18.626L8.72792 11.2721Z"
      fill="url(#paint0_linear_646_1979)"
      stroke="#262338"
      strokeWidth={3}
    />
    <defs>
      <linearGradient
        id="paint0_linear_646_1979"
        x1="3.76403"
        y1="31.764"
        x2="42.3638"
        y2="1.33931"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
    </defs>
  </svg>
)

export default Feature2
