import * as React from "react"
const Feature6 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={48}
    viewBox="0 0 44 48"
    fill="none"
  >
    <path
      d="M2.92711 8.88746C2.32806 9.60632 2 10.5125 2 11.4482V40C2 43.3137 4.68629 46 8 46H36C39.3137 46 42 43.3137 42 40V11.4482C42 10.5125 41.6719 9.60632 41.0729 8.88746L36.5327 3.43926C35.7727 2.52729 34.647 2 33.4598 2H10.5402C9.35305 2 8.22726 2.52729 7.46728 3.43926L2.92711 8.88746Z"
      fill="url(#paint0_linear_646_2008)"
    />
    <path
      d="M7.46728 3.43926L8.61961 4.39954L7.46728 3.43926ZM36.5327 3.43926L35.3804 4.39954V4.39954L36.5327 3.43926ZM41.0729 8.88746L42.2252 7.92719V7.92719L41.0729 8.88746ZM2.92711 8.88746L1.77478 7.92719H1.77478L2.92711 8.88746ZM31.5 20C31.5 19.1716 30.8284 18.5 30 18.5C29.1716 18.5 28.5 19.1716 28.5 20H31.5ZM15.5 20C15.5 19.1716 14.8284 18.5 14 18.5C13.1716 18.5 12.5 19.1716 12.5 20H15.5ZM42.2252 7.92719L37.6851 2.47899L35.3804 4.39954L39.9206 9.84774L42.2252 7.92719ZM33.4598 0.5H10.5402V3.5H33.4598V0.5ZM6.31495 2.47899L1.77478 7.92719L4.07945 9.84774L8.61961 4.39954L6.31495 2.47899ZM43.5 40V11.4482H40.5V40H43.5ZM0.5 11.4482V40H3.5V11.4482H0.5ZM8 47.5H36V44.5H8V47.5ZM0.5 40C0.5 44.1421 3.85787 47.5 8 47.5V44.5C5.51472 44.5 3.5 42.4853 3.5 40H0.5ZM40.5 40C40.5 42.4853 38.4853 44.5 36 44.5V47.5C40.1421 47.5 43.5 44.1421 43.5 40H40.5ZM10.5402 0.5C8.90788 0.5 7.35992 1.22503 6.31495 2.47899L8.61961 4.39954C9.0946 3.82956 9.79822 3.5 10.5402 3.5V0.5ZM37.6851 2.47899C36.6401 1.22503 35.0921 0.5 33.4598 0.5V3.5C34.2018 3.5 34.9054 3.82956 35.3804 4.39954L37.6851 2.47899ZM39.9206 9.84774C40.295 10.297 40.5 10.8634 40.5 11.4482H43.5C43.5 10.1616 43.0489 8.91562 42.2252 7.92719L39.9206 9.84774ZM1.77478 7.92719C0.951089 8.91562 0.5 10.1615 0.5 11.4482H3.5C3.5 10.8634 3.70504 10.297 4.07945 9.84774L1.77478 7.92719ZM2 12.5H42V9.5H2V12.5ZM28.5 20C28.5 23.5899 25.5899 26.5 22 26.5V29.5C27.2467 29.5 31.5 25.2467 31.5 20H28.5ZM22 26.5C18.4101 26.5 15.5 23.5899 15.5 20H12.5C12.5 25.2467 16.7533 29.5 22 29.5V26.5Z"
      fill="#262338"
    />
    <defs>
      <linearGradient
        id="paint0_linear_646_2008"
        x1={2}
        y1="15.42"
        x2="42.9764"
        y2="18.7289"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
    </defs>
  </svg>
)

export default Feature6
