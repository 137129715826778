import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Cheer from "../../svg/cheer"

const Join = () => {
  return (
    <div className={"join"}>
      <div className="join__background"></div>
      <h2>Dołącz do nas</h2>
      <p>Zostań kierowcą i zacznij zarabiać.</p>
      <div className="join__subtitle hero__subtitle">
        <Cheer />
        0 zł “wpisowego” do grupy!
      </div>
      <div>
        <Link className={"button button--secondary"} to={"/dolacz"}>
          Dowiedz się więcej
        </Link>
      </div>
      <div className="join__konfetti">
        <img src={"/images/join/konfetti.svg"} alt={"dołącz"} />
      </div>
    </div>
  )
}

export default Join
