import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Features from "../components/Common/features"
import Clients from "../components/Common/clients"
import AboutHero from "../components/About/aboutHero"
import Cars from "../components/About/cars"
import Join from "../components/Common/join"

const AboutPage = () => (
  <Layout>
    <Seo title={"O nas"} description={"Od ponad 25 lat pracujemy dla mieszkańców Szczecina. Poznaj nas i dołącz już dziś!"} />
    <div data-g="container">
      <AboutHero />
      <Cars />
      <hr className="separator"></hr>
      <Features />
      <Clients />
    </div>
    <Join />
  </Layout>
)

export default AboutPage
