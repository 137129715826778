import * as React from "react"
const Feature5 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={36}
    viewBox="0 0 48 36"
    fill="none"
  >
    <path
      d="M30 8C30 11.3137 27.3137 14 24 14C20.6863 14 18 11.3137 18 8C18 4.68629 20.6863 2 24 2C27.3137 2 30 4.68629 30 8Z"
      fill="url(#paint0_linear_646_2001)"
    />
    <path
      d="M12 14C12 16.2091 10.2091 18 8 18C5.79086 18 4 16.2091 4 14C4 11.7909 5.79086 10 8 10C10.2091 10 12 11.7909 12 14Z"
      fill="url(#paint1_linear_646_2001)"
    />
    <path
      d="M44 14C44 16.2091 42.2091 18 40 18C37.7909 18 36 16.2091 36 14C36 11.7909 37.7909 10 40 10C42.2091 10 44 11.7909 44 14Z"
      fill="url(#paint2_linear_646_2001)"
    />
    <path
      d="M14 30C14 24.4772 18.4772 20 24 20C29.5228 20 34 24.4772 34 30V34H14V30Z"
      fill="url(#paint3_linear_646_2001)"
    />
    <path
      d="M14.547 26.7297C14.1925 27.7544 14 28.8547 14 30V34H2V31C2 27.134 5.13401 24 9 24C11.2584 24 13.267 25.0695 14.547 26.7297Z"
      fill="url(#paint4_linear_646_2001)"
    />
    <path
      d="M33.453 26.7297C33.8075 27.7544 34 28.8547 34 30V34H46V31C46 27.134 42.866 24 39 24C36.7416 24 34.733 25.0695 33.453 26.7297Z"
      fill="url(#paint5_linear_646_2001)"
    />
    <path
      d="M34 34V30M34 34H14M34 34H46V31C46 27.134 42.866 24 39 24C36.7416 24 34.733 25.0695 33.453 26.7297C33.8075 27.7544 34 28.8547 34 30M14 34V30M14 34H2V31C2 27.134 5.13401 24 9 24C11.2584 24 13.267 25.0695 14.547 26.7297C14.1925 27.7544 14 28.8547 14 30M34 30C34 24.4772 29.5228 20 24 20C18.4772 20 14 24.4772 14 30M30 8C30 11.3137 27.3137 14 24 14C20.6863 14 18 11.3137 18 8C18 4.68629 20.6863 2 24 2C27.3137 2 30 4.68629 30 8ZM12 14C12 16.2091 10.2091 18 8 18C5.79086 18 4 16.2091 4 14C4 11.7909 5.79086 10 8 10C10.2091 10 12 11.7909 12 14ZM44 14C44 16.2091 42.2091 18 40 18C37.7909 18 36 16.2091 36 14C36 11.7909 37.7909 10 40 10C42.2091 10 44 11.7909 44 14Z"
      stroke="#262338"
      strokeWidth={3}
    />
    <defs>
      <linearGradient
        id="paint0_linear_646_2001"
        x1={2}
        y1="11.76"
        x2="46.7011"
        y2="17.2197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_646_2001"
        x1={2}
        y1="11.76"
        x2="46.7011"
        y2="17.2197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_646_2001"
        x1={2}
        y1="11.76"
        x2="46.7011"
        y2="17.2197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_646_2001"
        x1={2}
        y1="11.76"
        x2="46.7011"
        y2="17.2197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_646_2001"
        x1={2}
        y1="11.76"
        x2="46.7011"
        y2="17.2197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_646_2001"
        x1={2}
        y1="11.76"
        x2="46.7011"
        y2="17.2197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
    </defs>
  </svg>
)

export default Feature5
