import * as React from "react"
const Feature1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={47}
    viewBox="0 0 40 47"
    fill="none"
  >
    <path
      d="M2 31C2 28.7909 3.79086 27 6 27H34C36.2091 27 38 28.7909 38 31V45H2V31Z"
      fill="url(#paint0_linear_646_1972)"
    />
    <path
      d="M6 27H34V17C34 14.7909 32.2091 13 30 13H10C7.79086 13 6 14.7909 6 17V27Z"
      fill="url(#paint1_linear_646_1972)"
    />
    <path
      d="M18 8C18 9.65685 16.6569 11 15 11C13.3431 11 12 9.65685 12 8C12 6.34315 15 3 15 3C15 3 18 6.34315 18 8Z"
      fill="url(#paint2_linear_646_1972)"
    />
    <path
      d="M28 8C28 9.65685 26.6569 11 25 11C23.3431 11 22 9.65685 22 8C22 6.34315 25 3 25 3C25 3 28 6.34315 28 8Z"
      fill="url(#paint3_linear_646_1972)"
    />
    <path
      d="M34 27H6M34 27C36.2091 27 38 28.7909 38 31V45H2V31C2 28.7909 3.79086 27 6 27M34 27V17C34 14.7909 32.2091 13 30 13H10C7.79086 13 6 14.7909 6 17V27M6 21L8.39337 19.8033C9.4153 19.2923 10.607 19.2428 11.6678 19.6671L13.5144 20.4058C14.4681 20.7872 15.5319 20.7872 16.4856 20.4058L18.5144 19.5942C19.4681 19.2128 20.5319 19.2128 21.4856 19.5942L23.5144 20.4058C24.4681 20.7872 25.5319 20.7872 26.4856 20.4058L28.3322 19.6671C29.393 19.2428 30.5847 19.2923 31.6066 19.8033L34 21M2 37L5.83981 35.5067C6.68348 35.1786 7.6135 35.1464 8.47786 35.4153L12.3832 36.6303C13.157 36.8711 13.9858 36.8711 14.7597 36.6303L18.8117 35.3697C19.5856 35.1289 20.4144 35.1289 21.1883 35.3697L25.2403 36.6303C26.0142 36.8711 26.843 36.8711 27.6168 36.6303L31.5221 35.4153C32.3865 35.1464 33.3165 35.1786 34.1602 35.5067L38 37M18 8C18 9.65685 16.6569 11 15 11C13.3431 11 12 9.65685 12 8C12 6.34315 15 3 15 3C15 3 18 6.34315 18 8ZM28 8C28 9.65685 26.6569 11 25 11C23.3431 11 22 9.65685 22 8C22 6.34315 25 3 25 3C25 3 28 6.34315 28 8Z"
      stroke="#262338"
      strokeWidth={3}
    />
    <defs>
      <linearGradient
        id="paint0_linear_646_1972"
        x1={2}
        y1="15.81"
        x2="38.9053"
        y2="18.6199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_646_1972"
        x1={2}
        y1="15.81"
        x2="38.9053"
        y2="18.6199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_646_1972"
        x1={2}
        y1="15.81"
        x2="38.9053"
        y2="18.6199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_646_1972"
        x1={2}
        y1="15.81"
        x2="38.9053"
        y2="18.6199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFC" />
        <stop offset={1} stopColor="#D8D8D8" />
      </linearGradient>
    </defs>
  </svg>
)

export default Feature1
